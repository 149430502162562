

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #fff;
}

.playerfundo {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
}

.playerfundo:hover {
    /* transform: scale(1.1); */
}   

.redes {
    margin: 0px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.player-container {
    background-color: #f4f4f7;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
    margin-top: 20px;
    margin-bottom: 80px;
}

.logoRadio {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 350px;
    height: 300px;
    background-image: url("../Images/LogoRadio.jpg");
}